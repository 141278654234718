export type Questions = {
    id: number;
    question: string;
    type: string;
    min: number,
    max: number,
    options: Array<string>
    value?: string | number
    status: QuestionStatus
  }

  export enum QuestionStatus {
    NOT_ANSWERED = "NOT_ANSWERED",
    ANSWERED = "ANSWERED",
    SKIPPED = "SKIPPED",
  }