import {AuthContext} from "./auth/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Layout from "./pages/Layout";
import Protected from "./components/Protected";
import {
  ChakraProvider,
  extendBaseTheme,
  theme as chakraTheme,
} from "@chakra-ui/react";
// import { signinWithGoogle } from "./auth/auth";

function App() {
  // signinWithGoogle();
  const theme = extendBaseTheme({
    components: chakraTheme.components,
  });
  return (
    <ChakraProvider theme={theme}>
      <AuthContext>
        <Router>
          <Routes>
            <Route path="/home" element={<Protected><Layout /></Protected>} />
            <Route path="/" element={<Protected><Layout /></Protected>} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </Router>
      </AuthContext>
    </ChakraProvider>
  );
}

export default App;
