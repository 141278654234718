import React from 'react';

 
function Signup() {
 
  return (
    <>
    Signup</>
  );
}

export default Signup;
