import React, { ReactNode } from "react";
import { createContext, useState, useEffect } from "react";
import { Unsubscribe, User, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

export const AppAuthContext = createContext<AuthContextType|undefined>(undefined);

export type AuthContextType = {
  user: User | undefined;
  setUser: (user: User) => void;
};

export function AuthContext({ children }: { children: ReactNode}) {
  const [user, setUser] = useState<User>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let unsubscribe:Unsubscribe;
    unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setLoading(false);
      if (currentUser) setUser(currentUser);
      else {
        setUser(undefined);
      }
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const values = {
    user: user,
    setUser: setUser,
  };

  return <AppAuthContext.Provider value={values}>
  {!loading &&
   children
  }
</AppAuthContext.Provider>}
