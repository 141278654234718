import { NavigateFunction, useNavigate } from "react-router-dom";
import {
  Flex,
  Box,
  Stack,
  Heading,
  Image,
  Text,
  useColorModeValue,
  Center,
} from "@chakra-ui/react";

import { auth, ui } from "../firebase";
import {
  GoogleAuthProvider,
  setPersistence,
  browserLocalPersistence,
} from "firebase/auth";

const provider = new GoogleAuthProvider();

const login = async (navigate: NavigateFunction) => {
  await setPersistence(auth, browserLocalPersistence);

  ui.start("#login-placeholder", {
    signInOptions: [provider.providerId],
    callbacks: {
      signInSuccessWithAuthResult: function (authResult, redirectUrl) {
        return true;
      },
    },
    signInSuccessUrl: "home",
    signInFlow: "popup",
  });
};

export default function Login() {
  const navigate = useNavigate();
  login(navigate);
  return (
    <Flex
      minH={"100vh"}
      align={"center"}
      justify={"center"}
      bg={useColorModeValue("gray.50", "gray.800")}
    >
      <Stack>
        <Center>
          <Image boxSize="8em" src="../logo.svg" />
        </Center>
        <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
          <Stack align={"center"}>
            <Heading fontSize={"4xl"}>Sign in to Tech Duel</Heading>
            <Text fontSize={"lg"} color={"gray.600"}>
              POCs should't be this hard.
            </Text>
          </Stack>
          <Box
            rounded={"lg"}
            bg={useColorModeValue("white", "gray.700")}
            boxShadow={"lg"}
            p={8}
          >
            <Stack spacing={4} id="login-placeholder"></Stack>
          </Box>
        </Stack>
      </Stack>
    </Flex>
  );
}
