import { Navigate } from "react-router-dom";
import { ReactNode, useContext } from "react";
import { AppAuthContext } from "../auth/AuthContext";

export default function Protected({ children }: { children: ReactNode}){
    const context = useContext(AppAuthContext);

    if(!context?.user){
        return <Navigate to="/login" replace/>
    }else{
        return <>{children}</>;
    }
}