import { useState } from "react";
import "./Layout.css";
import {
  Link,
  Textarea,
  Button,
  Center,
  Table,
  Thead,
  CircularProgress,
  CircularProgressLabel,
  Text,
  Tbody,
  Tr,
  Image,
  Th,
  Td,
  Flex,
  Box,
  Slider,
  Divider,
  SliderTrack,
  SliderFilledTrack,
  SimpleGrid,
  SliderThumb,
  Container,
  VStack,
  Stack,
  RadioGroup,
  Radio,
  Spacer,
  Skeleton,
  useToast,
  AccordionItem,
  AccordionIcon,
  Accordion,
  AccordionButton,
  AccordionPanel,
} from "@chakra-ui/react";
import { ViewIcon, ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteCreatable,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { technologies } from "../services/technolgies.store";
import { API_BASE_URL } from "../config";
import { QuestionStatus, Questions } from "../types/questions";
import { ChatCompletionMessageParam } from "openai/resources";
import { userAnswers } from "../services/openai.store";
import * as Sentry from "@sentry/react";

function getTechs() {
  return [...new Set(technologies)];
}

const techs = getTechs();

export default function Layout() {
  const [tech1, setTech1] = useState<string>();
  const [tech2, setTech2] = useState<string>();
  const [userContextAndUseCase, setUserContextAndUseCase] = useState<string>();
  const [isFetchingQuestions, setIsFetchingQuestions] =
    useState<boolean>(false);
  const [questions, setQuestions] = useState<Array<Questions>>([]);
  const [conversations, setConversations] = useState<
    Array<ChatCompletionMessageParam>
  >([]);

  const [isFetchingAdvice, setIsFetchingAdvice] = useState<boolean>(false);
  const [advices, setAdvices] = useState<any>();
  const [currentQuestion, setCurrentQuestion] = useState<number>(0);
  const toast = useToast();

  const fetchQuestions = async () => {
    if (!tech1 || !tech2) {
      toast({
        title: "Choose two technologies first",
        status: "warning",
        isClosable: true,
      });
      return;
    }
    if (tech1 === tech2) {
      toast({
        title: "Nice try :-)",
        status: "info",
        isClosable: true,
      });
      return;
    }
    setIsFetchingQuestions(true);
    try {
      const response = await fetch(`${API_BASE_URL}/questions`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ tech1, tech2, context: userContextAndUseCase }),
      });
      const { initialChat, questions } = await response.json();
      setQuestions(questions);
      setIsFetchingQuestions(false);
      setConversations([
        ...initialChat,
        { role: "assistant", content: JSON.stringify(questions) },
      ]);

      setCurrentQuestion(0);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsFetchingQuestions(false);
    }
  };

  const handleYesNoAnswer = (question: Questions, answer: string) => {
    userAnswers[question.id] = answer;
    question.value = answer;
    question.status = QuestionStatus.ANSWERED;
    setQuestions([...(questions ?? [])]);
    handleQuestionChange(1);
  };

  const handleRangeAnswer = (question: Questions, answer: number) => {
    userAnswers[question.id] = answer;
    question.value = answer;
    question.status = QuestionStatus.ANSWERED;
    setQuestions([...(questions ?? [])]);
  };

  const handleMultiAnswer = (question: Questions, answer: string) => {
    userAnswers[question.id] = answer;
    question.value = answer;
    question.status = QuestionStatus.ANSWERED;
    setQuestions([...(questions ?? [])]);
    handleQuestionChange(1);
  };

  const getMessagesFromAnswers = () => {
    const messages = [];
    for (const [key, value] of Object.entries(userAnswers)) {
      if (key && value)
        messages.push({
          role: "user",
          content: `The answer for question ${key} is ${value}` as string,
        } as ChatCompletionMessageParam);
    }
    return messages;
  };

  const fetchAdvice = async () => {
    setIsFetchingAdvice(true);
    try {
      const rawResponse = await fetch(`${API_BASE_URL}/advice`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tech1,
          tech2,
          conversations: [...conversations, ...getMessagesFromAnswers()],
        }),
      });
      const advice = await rawResponse.json();
      setAdvices(advice);
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setIsFetchingAdvice(false);
    }
  };

  // const fetchBlog = async () => {
  //   try {
  //     const rawResponse = await fetch(`${API_BASE_URL}/blog`, {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         tech1,
  //         tech2,
  //         conversations: [...conversations, ...getMessagesFromAnswers()],
  //       }),
  //     });
  //     const advice = await rawResponse.json();
  //     console.log(advice);
  //   } catch (error) {
  //     Sentry.captureException(error);
  //   } finally {
  //   }
  // };

  const getQuestionColor = (question: Questions) => {
    switch (question.status) {
      case QuestionStatus.ANSWERED:
        return "green";
      case QuestionStatus.SKIPPED:
        return "red";
      case QuestionStatus.NOT_ANSWERED:
        return "gray";
    }
  };

  function handleQuestionChange(change: number) {
    if (
      change + currentQuestion < 0 ||
      change + currentQuestion >= questions.length
    )
      return;
    setCurrentQuestion(currentQuestion + change);
  }

  function handleIgnoreQuestion(index: number) {
    questions[index].status = QuestionStatus.SKIPPED;
    delete questions[index].value;
    setQuestions([...questions]);
    handleQuestionChange(1);
  }

  return (
    <Flex bg="#F8F9FA" height="100vh" >
      <Box
        p="1"
        flex="1"
        width={[
          "0%", // 0-30em
          "100%", // 0-30em
        ]}
      >
        <Container centerContent>
          <Image boxSize="6em" src="../logo.svg" />
        </Container>
        <Divider color="gray.500" mt="1" />
        <VStack
          p="1"
          spacing={4}
          align="stretch"
          ml="6"
          mr="6"
          mt="2"
          fontSize="sm"
        >
          <Link boxShadow="sm" p="2" rounded="md" bg="white" fontWeight="bold">
            <ViewIcon
              mr="4"
              ml="4"
              color="#4FD1C5"
              style={{ width: "1.5em", height: "1.5em" }}
            />
            Compare
          </Link>
          {/* <Link p="2" textColor="#A0AEC0">
            <ChatIcon
              mr="4"
              ml="4"
              color="#4FD1C5"
              style={{ width: "1.5em", height: "1.5em" }}
            />
            History
          </Link>
          <Link p="2" textColor="#A0AEC0">
            <StarIcon
              mr="4"
              ml="4"
              color="#4FD1C5"
              style={{ width: "1.5em", height: "1.5em" }}
            />
            Profile
          </Link> */}
        </VStack>
      </Box>
      <Box p="1" flex="6" pr={['0','0','0','10vw']}>
        <Text fontWeight="extrabold" fontSize="2xl" mt="10" mb="6">
          Compare
        </Text>
        <Box boxShadow="sm" p="2" rounded="md" bg="white">
          <Text fontWeight="bold" fontSize="lg">
            Choose two technologies to compare
          </Text>

          <Text fontSize="xs" color="#A0AEC0" mt="2">
            You can change it at any time
          </Text>
          <SimpleGrid
            columns={2}
            spacing="10"
            mt="2"
            maxWidth={[
              "80%", // 0-30em
              "50%",
            ]}
          >
            <AutoComplete onChange={setTech1} openOnFocus creatable>
              <AutoCompleteInput
                size="sm"
                variant="flushed"
                placeholder="Search for first technology"
                focusBorderColor="green"
              />
              <AutoCompleteList>
                <AutoCompleteCreatable alwaysDisplay={false} />

                {techs.map((country, cid) => (
                  <AutoCompleteItem
                    key={`option-${cid}`}
                    value={country}
                    textTransform="capitalize"
                  >
                    {country}
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            </AutoComplete>
            <AutoComplete openOnFocus onChange={setTech2} creatable>
              <AutoCompleteInput
                size="sm"
                variant="flushed"
                placeholder="And another"
                focusBorderColor="green"
              />
              <AutoCompleteList>
                <AutoCompleteCreatable alwaysDisplay={false} />

                {techs.map((country, cid) => (
                  <AutoCompleteItem
                    key={`option-${cid}`}
                    value={country}
                    textTransform="capitalize"
                  >
                    {country}
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            </AutoComplete>
          </SimpleGrid>

          <Text fontWeight="bold" fontSize="lg" mt="6">
            Share about your team and use cases
          </Text>

          <Text fontSize="xs" color="#A0AEC0" mt="2">
            It will help personalize our questions to you, ultimately better
            advice
          </Text>
          <SimpleGrid
            columns={{ base: 1, sm: 2 }}
            spacing="10"
            mt="2"
            alignItems="end"
          >
            <Textarea
              onChange={(e) => {
                setUserContextAndUseCase(e.target.value);
              }}
              placeholder="The more details the better, don't be shy"
              size="xs"
              m="1"
            />
            <Button
              maxWidth="10em"
              mb="2"
              size="sm"
              colorScheme="green"
              verticalAlign="bottom"
              isLoading={isFetchingQuestions}
              onClick={fetchQuestions}
            >
              Let's go!
            </Button>
          </SimpleGrid>
        </Box>
        <Box boxShadow="sm" p="2" rounded="md" bg="white" mt="6">
          {isFetchingQuestions && (
            <Stack>
              <Skeleton height="20px" />
              <Skeleton height="20px" />
              <Skeleton height="20px" />
            </Stack>
          )}
          {!isFetchingQuestions && (
            <>
              <Text fontWeight="bold" fontSize="lg">
                Our questions to you
              </Text>

              <Text fontSize="xs" color="#A0AEC0" mt="2">
                Answer to get an advice, click N/A when irrelevant
              </Text>
              <Center pr="20%" pl="20%">
                {questions.map((question, index) => {
                  return (
                    <Radio
                      m="1"
                      value={index.toString()}
                      colorScheme={getQuestionColor(question)}
                      onClick={() => {
                        setCurrentQuestion(index);
                      }}
                      bgColor={
                        index === currentQuestion ? "gray.100" : undefined
                      }
                      isChecked={
                        question.status === QuestionStatus.ANSWERED ||
                        question.status === QuestionStatus.SKIPPED
                      }
                    />
                  );
                })}
              </Center>

              <Flex
                bg="gray.100"
                borderRadius="10"
                mt="2"
                ml="10"
                mr="10"
                alignItems="center"
                p="5"
              >
                <Button
                  leftIcon={
                    <ChevronLeftIcon
                      style={{ width: "1.5em", height: "1.5em" }}
                    />
                  }
                  onClick={() => {
                    handleQuestionChange(-1);
                  }}
                />
                <Spacer />
                <Stack alignItems="center">
                  {questions.length > 0 &&
                    questions?.map((question, index) => {
                      if (index !== currentQuestion) return <></>;
                      let content;
                      switch (question.type) {
                        case "yes_no":
                          content = (
                            <RadioGroup
                              onChange={(e) => {
                                handleYesNoAnswer(question, e);
                              }}
                              value={question.value?.toString() ?? "choose"}
                            >
                              <Stack direction="row">
                                <Radio value="yes">Yes</Radio>
                                <Radio value="no">No</Radio>
                              </Stack>
                            </RadioGroup>
                          );
                          break;
                        case "scale":
                          content = (
                            <Slider
                              aria-label="slider-ex-3"
                              minH="8"
                              min={question.min}
                              max={question.max}
                              colorScheme="green"
                              onChange={(e) => {
                                handleRangeAnswer(question, e);
                              }}
                              value={(question.value as number) ?? -1}
                              defaultValue={5}
                            >
                              <SliderTrack>
                                <SliderFilledTrack />
                              </SliderTrack>
                              <SliderThumb />
                            </Slider>
                          );
                          break;
                        case "multiple_choice":
                          content = (
                            <RadioGroup
                              onChange={(e) => {
                                handleMultiAnswer(question, e);
                              }}
                              value={question.value?.toString() ?? "choose"}
                            >
                              <Stack direction="row">
                                {question.options.map((option, index) => {
                                  return (
                                    <Radio key={index} value={option}>
                                      {option}
                                    </Radio>
                                  );
                                })}
                              </Stack>
                            </RadioGroup>
                          );
                          break;
                      }
                      return (
                        <>
                          <Text>{question.question}</Text>
                          {content}
                        </>
                      );
                    })}
                  {questions.length === 0 && (
                    <Text>Waiting for you to select technologies</Text>
                  )}
                </Stack>
                <Spacer />

                <Button
                  leftIcon={
                    <ChevronRightIcon
                      style={{ width: "1.5em", height: "1.5em" }}
                    />
                  }
                  onClick={() => {
                    handleQuestionChange(+1);
                  }}
                />
              </Flex>

              <Center mt="4" mb="4">
                <Button
                  variant="outline"
                  size="sm"
                  m="1"
                  onClick={() => {
                    handleIgnoreQuestion(currentQuestion);
                  }}
                  isDisabled={questions?.length === 0}
                >
                  Ignore question
                </Button>
                <Button
                  variant="outline"
                  size="sm"
                  m="1"
                  colorScheme="green"
                  isLoading={isFetchingAdvice}
                  isDisabled={questions.length === 0}
                  onClick={() => {
                    fetchAdvice();
                  }}
                >
                  Give me advice
                </Button>
              </Center>
            </>
          )}
        </Box>
        <Box boxShadow="sm" p="2" rounded="md" bg="white" mt="6">
          <Text fontWeight="bold" fontSize="lg">
            Our recommendation to you
          </Text>

          <Text fontSize="xs" color="#A0AEC0" mt="2">
            You can answer more questions to get a better advice
          </Text>
          <Box>
            {!advices && !isFetchingAdvice && (
              <Center m="2">
                <Text>Waiting for you to answer questions</Text>
              </Center>
            )}
            {isFetchingAdvice && (
              <Stack m="2">
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
              </Stack>
            )}
            {advices && (
              // <Center mr="20" ml="20" mt="2">
              //   <Text fontWeight="bold">{tech1}</Text>
              //   <Slider
              //     aria-label="slider-ex-3"
              //     minH="8"
              //     mr="4"
              //     ml="4"
              //     min={1}
              //     max={10}
              //     colorScheme="blue"
              //     defaultValue={5}
              //     value={advices?.confidence_score || 5}
              //   >
              //     <SliderTrack>
              //       <SliderFilledTrack />
              //     </SliderTrack>
              //     {/* <SliderThumb /> */}
              //   </Slider>
              //   <Text fontWeight="bold">{tech2}</Text>
              // </Center>
              <Center mr="20" ml="20" mt="2">
                <VStack>
                  <Text fontWeight="bold">
                    {advices?.confidence_score?.tech_name}
                  </Text>

                  <CircularProgress
                    mt="4"
                    size="100px"
                    value={advices?.confidence_score?.score}
                    color="green.400"
                  >
                    <CircularProgressLabel>
                      {advices?.confidence_score?.score}%
                    </CircularProgressLabel>
                  </CircularProgress>
                </VStack>
              </Center>
            )}
          </Box>
          {advices && (
            <Accordion mt="6" defaultIndex={[0]} allowMultiple>
              <AccordionItem>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">Short advice</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {" "}
                  {advices?.short_advice}{" "}
                </AccordionPanel>
              </AccordionItem>

              <AccordionItem>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold"> Long advice</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>{advices?.long_advice}</AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold"> Additional context</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>

                <AccordionPanel pb={4}>
                  {advices?.additional_context}
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    <Text fontWeight="bold">Answer by Answer details</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel pb={4}>
                  {advices?.answer_details?.map((a: any) => {
                    return <Text>{a}</Text>;
                  })}
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          )}
        </Box>
        <Box boxShadow="sm" p="2" rounded="md" bg="white" mt="6">
          <Text fontWeight="bold" fontSize="lg">
            Comparison table based on your answers
          </Text>

          <Text fontSize="xs" color="#A0AEC0" mt="2">
            The more answers the better
          </Text>
          {!advices && !isFetchingAdvice && (
            <Center m="2">
              <Text>Waiting for you to answer questions</Text>
            </Center>
          )}
          {advices?.comparison_table && (
            <Table>
              <Thead>
                <Tr>
                  {advices?.comparison_table?.headers.map(
                    (header: string, index: number) => (
                      <Th>{header}</Th>
                    )
                  )}
                </Tr>
              </Thead>
              <Tbody>
                {advices?.comparison_table?.rows.map(
                  (row: string[], index: number) => {
                    return (
                      <Tr>
                        {row.map((cell: string, index: number) => {
                          return <Td fontSize="sm">{cell as string}</Td>;
                        })}
                      </Tr>
                    );
                  }
                )}
              </Tbody>
            </Table>
          )}
          {/* <Button onClick={() => fetchBlog()}>Generate blog</Button> */}
        </Box>
      </Box>
    </Flex>
  );
}
