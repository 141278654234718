export const technologies = [
  // Databases
  "PostgreSQL",
  "MySQL",
  "MongoDB",
  "Oracle",
  "Microsoft SQL Server",
  "Redis",
  "Cassandra",
  "Firebase",
  "Elasticsearch",
  "DynamoDB",
  "CouchDB",
  "Neo4j",
  "ArangoDB",
  "InfluxDB",
  "Snowflake",
  "Teradata",
  "CockroachDB",
  "TimescaleDB",
  "RavenDB",
  "ScyllaDB",
  "Couchbase",
  "Aerospike",
  "OpenTSDB",
  "ClickHouse",

  // Compute
  "AWS EC2",
  "Google Compute Engine",
  "Azure Virtual Machines",
  "DigitalOcean",
  "Kubernetes",
  "Docker",
  "Lambda",
  "Cloud Functions",
  "Fargate",
  "GKE",
  "AKS",
  "EKS",
  "App Engine",
  "Heroku",
  "Azure App Services",
  "Netlify",
  "Vercel",
  "OpenFaaS",
  "Kubeless",
  "Apache OpenWhisk",
  "Rancher",

  // Backend Languages
  "JavaScript",
  "Python",
  "Java",
  "Ruby",
  "PHP",
  "C#",
  "Go",
  "Rust",
  "Node.js",
  "Scala",
  "Kotlin",
  "Swift",
  "TypeScript",
  "Clojure",
  "Groovy",
  "Lua",
  "R",
  "Objective-C",
  "Shell",
  "PowerShell",
  "Erlang",
  "Bash",
  "React",
  "Angular",
  "Vue.js",
  "Polymer",
  "Redux",
  "MobX",
  "Nuxt.js",
  "Next.js",
  "Qwik",
  "Storybook",
  "Bootstrap",
  "Tailwind CSS",
  "TypeScript",
  "Groovy",
  // Message Brokers
  "Apache Kafka",
  "RabbitMQ",
  "ActiveMQ",
  "Amazon SNS",
  "Amazon SQS",
  "Google Pub/Sub",
  "Azure Service Bus",
  "Redis Streams",
  "Apache Pulsar",
  "Kafka Streams",
  "Flink",
  "Amazon Kinesis",
  "Google Dataflow",
  "Azure Event Hubs",
  "AWS Step Functions",
  "Confluent",
  "Kafka Connect",
  "Kinesis Data Streams",
  "Amazon EventBridge",
  "Microsoft Azure Event Grid",
  "RabbitMQ Streams",
  // Data Warehouses
  "Snowflake",
  "Amazon Redshift",
  "Google BigQuery",
  "Azure Synapse Analytics",
  "Vertica",
  "Firebolt",
  "Presto",
  "Apache Hive",
  "Apache Impala",
  "Databricks Lakehouse",
  "Amazon Redshift Spectrum",
  "Google BigQuery Omni",
  "Microsoft Azure SQL Data Warehouse",
  "Sisense",
  "Tableau",
  "Power BI",
  // Version Control Systems
  "Bitbucket",
  "GitHub",
  "GitLab",
  // CI/CD Tools
  "Jenkins",
  "GitHub Actions",
  "GitLab CI",
  "CircleCI",
  "Travis CI",
  "Bamboo",
  "TeamCity",
  "Azure Pipelines",
  "AWS CodePipeline",
  "Bitbucket Pipelines",
  "Argo",
  "Octopus Deploy",
  "JFrog Pipelines",
  "Ansible",
  "Chef",
  "Puppet",
  "Terraform",
  "Vault",
  "Codefresh",
  "Google Cloud Build",
  "Azure DevOps",
  "AWS CodeBuild",

  // Containerization Platforms
  "Docker",
  "Kubernetes",
  "OpenShift",
  "ECS",
  "EKS",
  "GKE",
  "AKS",
  "AWS Fargate",
  "Istio",
  // Orchestration Tools
  
  "Helm",
  

  // API Gateways
  "Kong",
  "Tyk",
  "Apigee",
  "AWS API Gateway",
  "Azure API Management",
  "Google Cloud Endpoints",
  "Nginx",
  "Amazon API Gateway",
  "Azure API Management",
  "Cloudflare Workers",
  "SwaggerHub",
  "RapidAPI",
  // Authentication Services
  "Auth0",
  "Okta",
  "Firebase Authentication",
  "AWS Cognito",
  "Azure AD B2C",
  "Google Identity Platform",
  // Caching Solutions
  "Redis",
  "Memcached",
  "Varnish",
  "Apache Ignite",
  "Amazon ElastiCache",
  "Google Cloud Memorystore",
  "Azure Cache for Redis",
  // Search Engines
  "Elasticsearch",
  "Solr",
  "Apache Lucene",
  "Amazon CloudSearch",
  "Microsoft Azure Search",
  "Google Cloud Search",
  "Splunk",
  "Neo4j",
  // Monitoring Tools
  "Prometheus",
  "Grafana",
  "Datadog",
  "Splunk",
  "New Relic",
  "AppDynamics",
  "Dynatrace",
  "ELK Stack",
  "PagerDuty",
  "OpsGenie",
  "Pingdom",
  "SolarWinds",
  "GCP Monitoring",
  "Azure Monitor",
  "Sentry",
  "Rollbar",
  "BigPanda",
  "Grafana Loki",
  "Logz.io",

  // Logging Tools
  "Fluentd",
  "Logstash",
  "Datadog",
  "Kibana",
  "Sentry",
  "Loki",
  "New Relic",
  "CloudWatch Logs",
  "Azure Monitor Logs",
  "GCP Cloud Logging",
  "Logz.io",
  "Splunk",

  // Configuration Management Tools
  "Ansible",
  "Chef",
  "Puppet",
  "Terraform",
  "Vagrant",
  "Consul",
  "Vault",

  // Infrastructure as Code (IaC) Tools
  "CloudFormation",
  "Pulumi",
  "Puppet",
  "AWS CDK",
  "Azure Bicep",
  "Google Deployment Manager",

  // Development Frameworks
  "Spring",
  "Django",
  "Ruby on Rails",
  "Laravel",
  "Express",
  "ASP.NET",
  "Flask",
  "Angular",
  "React",
  "Vue.js",
  "NestJS",
  "Next.js",
  "Nuxt.js",

  // Mobile Development Frameworks
  "React Native",
  "Flutter",
  "Ionic",
  "Cordova",
  "PhoneGap",
  "Xamarin",
  "NativeScript",
  "Swift",
  "Objective-C",
  // Build Tools
  "Maven",
  "Gradle",
  "Ant",
  "Make",
  "Bazel",
  "Webpack",
  "SBT",
  "MSBuild",
  "Xcode Build",
  "Yarn",
  "npm",
  "NuGet",
  "pip",


  // CDN Providers
  "Cloudflare",
  "Akamai",
  "Fastly",
  "Amazon CloudFront",
  "Azure CDN",
  "Google Cloud CDN",
  // Edge Computing Platforms
  "AWS Lambda",
  "Azure Functions",
  "Google Cloud Functions",
  "Cloudflare Workers",
  "Fastly Compute@Edge",
  "Akamai EdgeWorkers",
  // Email Services
  "SendGrid",
  "Mailgun",
  "Amazon SES",
  "Postmark",
  "Mailchimp",
  "Mandrill",

  // Payment Gateways
  "PayPal",
  "Stripe",
  "Braintree",
  "BlueSnap",


  /// Object storage
  "Amazon S3",
  "Google Cloud Storage",
  "Microsoft Azure Blob Storage",
  "DigitalOcean Spaces",


  /// CNCF projects
  "Kubernetes",
  "Prometheus",
  "Fluentd",
  "Jaeger",
  "Helm",
  "gRPC",
  "OpenTelemetry",
   // AWS Services
   'Amazon EC2',
   'Amazon S3',
   'Amazon RDS',
   'Amazon DynamoDB',
   'Amazon Lambda',
   'Amazon Redshift',
   'Amazon CloudFront',
   'Amazon CloudWatch',
   'Amazon SNS',
   'Amazon SQS',
   'Amazon EFS',
   'Amazon Kinesis',
   'Amazon Route 53',
   'Amazon Glacier',
   'Amazon ElastiCache',
   'Amazon ECS',
   'Amazon EKS',
   'Amazon MQ',
   'Amazon Neptune',
   'Amazon SageMaker',
   'Amazon Rekognition',
   'Amazon Textract',
   'Amazon Comprehend',
   'Amazon Polly',
   'Amazon Translate',
   'Amazon Personalize',
   'Amazon Forecast',
   'AWS Batch',
   'AWS Glue',
   'AWS Step Functions',
   'AWS CodePipeline',
   'AWS CodeBuild',
   'AWS CodeDeploy',
   'AWS CodeCommit',
   'AWS CodeStar',
   'AWS CloudFormation',
   'AWS CloudTrail',
   'AWS OpsWorks',
   'AWS Config',
   'AWS Secrets Manager',
   'AWS IAM',
   'AWS Cognito',
   'AWS Organizations',
   'AWS Control Tower',
   'AWS Elastic Beanstalk',
   'AWS App Mesh',
   'AWS Fargate',
   'AWS WAF',
   'AWS Shield',
   'AWS Macie',
   'AWS Firewall Manager',
   'AWS Security Hub',
   'AWS Outposts',
   'AWS Snowball',
   'AWS Snowcone',
   'AWS Snowmobile',
   'AWS Greengrass',
   'AWS IoT Core',
   'AWS IoT Analytics',
   'AWS IoT Events',
   'AWS IoT Greengrass',
   'AWS IoT Things Graph',
   'AWS IoT SiteWise',
   'AWS IoT Device Defender',
   'AWS IoT Device Management',
   'AWS AppSync',
   'AWS Amplify',
   'AWS Mobile Hub',
   'AWS Device Farm',
   'AWS App Runner',
   'AWS Backup',
   'AWS DataSync',
   'AWS Transfer Family',
   'AWS Database Migration Service',
   'AWS Application Migration Service',
 
   // GCP Services
   'Google Compute Engine',
   'Google App Engine',
   'Google Kubernetes Engine',
   'Google Cloud Functions',
   'Google Cloud Run',
   'Google Cloud Storage',
   'Google Cloud SQL',
   'Google Cloud Spanner',
   'Google BigQuery',
   'Google Cloud Pub/Sub',
   'Google Cloud Dataflow',
   'Google Cloud Dataproc',
   'Google Cloud Composer',
   'Google Cloud Data Fusion',
   'Google Cloud Bigtable',
   'Google Cloud Firestore',
   'Google Cloud Memorystore',
   'Google Cloud Filestore',
   'Google Cloud Endpoints',
   'Google Cloud Armor',
   'Google Cloud CDN',
   'Google Cloud Interconnect',
   'Google Cloud DNS',
   'Google Cloud VPN',
   'Google Cloud Load Balancing',
   'Google Cloud VPC',
   'Google Cloud IAM',
   'Google Cloud Resource Manager',
   'Google Cloud Security Command Center',
   'Google Cloud Identity',
   'Google Cloud Key Management',
   'Google Cloud HSM',
   'Google Cloud Secret Manager',
   'Google Anthos',
   'Google Cloud Marketplace',
   'Google Cloud Deployment Manager',
   'Google Cloud Operations Suite',
   'Google Cloud Logging',
   'Google Cloud Monitoring',
   'Google Cloud Trace',
   'Google Cloud Debugger',
   'Google Cloud Profiler',
   'Google Cloud AI Platform',
   'Google AutoML',
   'Google Cloud Natural Language',
   'Google Cloud Speech-to-Text',
   'Google Cloud Text-to-Speech',
   'Google Cloud Translation',
   'Google Cloud Vision',
   'Google Cloud Video Intelligence',
   'Google Cloud Recommendation AI',
   'Google Cloud Healthcare API',
   'Google Cloud Life Sciences',
   'Google Cloud Datastore',
   'Google Cloud IoT Core',
   'Google Cloud BigQuery ML',
   'Google Cloud AutoML Tables',
   'Google Cloud AutoML Natural Language',
   'Google Cloud AutoML Translation',
   'Google Cloud AutoML Vision',
   'Google Cloud Contact Center AI',
   'Google Cloud Document AI',
   'Google Cloud Talent Solution',
 
   // Azure Services
   'Azure Virtual Machines',
   'Azure App Services',
   'Azure Kubernetes Service',
   'Azure Functions',
   'Azure Container Instances',
   'Azure Blob Storage',
   'Azure File Storage',
   'Azure Queue Storage',
   'Azure Table Storage',
   'Azure SQL Database',
   'Azure Cosmos DB',
   'Azure Database for MySQL',
   'Azure Database for PostgreSQL',
   'Azure Database for MariaDB',
   'Azure Synapse Analytics',
   'Azure HDInsight',
   'Azure Databricks',
   'Azure Stream Analytics',
   'Azure Data Factory',
   'Azure Data Lake Storage',
   'Azure Data Explorer',
   'Azure Cognitive Services',
   'Azure Machine Learning',
   'Azure Bot Service',
   'Azure Search',
   'Azure SignalR Service',
   'Azure API Management',
   'Azure Logic Apps',
   'Azure Event Grid',
   'Azure Event Hubs',
   'Azure Service Bus',
   'Azure Notification Hubs',
   'Azure CDN',
   'Azure Traffic Manager',
   'Azure Front Door',
   'Azure DNS',
   'Azure ExpressRoute',
   'Azure Virtual Network',
   'Azure VPN Gateway',
   'Azure Application Gateway',
   'Azure Load Balancer',
   'Azure Bastion',
   'Azure Firewall',
   'Azure DDoS Protection',
   'Azure Sentinel',
   'Azure Security Center',
   'Azure Key Vault',
   'Azure Active Directory',
   'Azure AD B2C',
   'Azure Policy',
   'Azure Blueprints',
   'Azure Resource Manager',
   'Azure Monitor',
   'Azure Log Analytics',
   'Azure Application Insights',
   'Azure Automation',
   'Azure Backup',
   'Azure Site Recovery',
   'Azure Arc',
   'Azure DevOps',
   'Azure Pipelines',
   'Azure Repos',
   'Azure Artifacts',
   'Azure Boards',
   'Azure Test Plans',
   'Azure Lab Services',
   'Azure DevTest Labs',
   'Azure Blockchain Service',
   'Azure Digital Twins',
   'Azure IoT Hub',
   'Azure IoT Central',
   'Azure Sphere',
   'Azure Maps',
   'Azure Remote Rendering',
   'Azure Spatial Anchors',
   'Azure Percept',
   'Azure Media Services',
   'Azure Video Analyzer',
   'Azure Cognitive Search',
   'Azure Form Recognizer',
   'Azure Immersive Reader',
   'Azure Custom Vision',
   'Azure QnA Maker',
   'Azure Translator',
   'Azure Speech Services',
   'Azure Metrics Advisor',
   'Azure Personalizer',
   'Azure Bot Framework',


/// Data Lakes
 /// Gaming frameworks

  "Unity",
  "Unreal Engine",
  "Godot",
  "GameMaker Studio",


];
